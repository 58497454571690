@media only screen and (min-width: 713px) {
  .usersActions {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 712px) {
  .usersActions {
    display: flex;
    flex-direction: column;
  }

  .dashboardDeleteIcon {
    margin: 0px 32px 0px 19px;
  }
}

#dialogContent button {
  background: transparent;
  outline: transparent;
  border: 1px solid white;
  cursor: pointer;
  font-size: 16px;
  color: blue;
  text-decoration: underline;
}

#iconsBlock button {
  background: transparent;
  outline: transparent;
  border: 1px solid white;
  font-size: 16px;
  color: balck;
  text-decoration: underline;
}

.cursorPointer {
  cursor: pointer;
}

@media only screen and (max-width: 539px) {
  .lockUserTitle {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 540px) {
  .lockUserTitle {
    display: flex;
    flex-direction: row;
  }
}
