@media only screen and (max-width: 399px) {
  #globalSearchTitleBlock {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .globalSearchTitleSubBlock {
    display: none;
  }

  #globalSearchTitleBlock .MuiFormControl-root {
    margin: 0;
  }
  .listRepeater ul li div.MuiBox-root subDiv{
    height: 104px;
  }  
}

@media only screen and (min-width: 400px) {
  #globalSearchTitleBlock {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
  }

  .globalSearchTitleSubBlock {
    display: flex;
  }
}

.globalSearchContent .MuiTabs-indicator {
  background: #c2185b;
}

.pagination div.MuiInputBase-root.MuiInputBase-colorPrimary {
    display: none !important;
}

.pagination p.MuiTablePagination-selectLabel div.MuiInputBase-root.MuiInputBase-colorPrimary {
    display: inline-flex !important;
    height: 25px;
    font-size:13px;
}

tbody tr {
  border-top:1.1px solid rgb(206, 189, 206) !important;    
}

.pagination_rowPerPageLabelDiv__3bkx3 .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 25px;
    font-size:13px;
}

.pagination p.MuiTablePagination-displayedRows {
    margin-right:32px !important;
    font-size:13px;
}


.pagination .css-levciy-MuiTablePagination-displayedRows {
    margin-right:32px !important;
    font-size:13px;
}

.lockUsersTableBody td {
    align-items:center;
    justify-content:center;
    line-height:0.99;
    font-size: 13px;
}

@media screen and (max-width: 650px) {
    div.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
        display:flex !important;
        flex-direction:column !important;
        align-items:end !important;
        padding-bottom: 14px !important;
    }
    .historyDialogContent {
      height: 100vh !important;
    }
}

.historyDialogContent {
  max-height: 50vh !important;
  height: auto;
}

.autocompleteblock div.MuiOutlinedInput-root{
    height: 30px !important;
    padding: 0px 10px 0px 10px !important;
    background: #fff;
    color: #000;
}

.autocompleteblock .MuiAutocomplete-input {
    padding: 0px !important;
    margin: 0px !important;
}

.autocompleteblock button.MuiButtonBase-root.MuiIconButton-root {
    visibility: visible !important;
}

.listRepeater {
    width: 100% !important;
}

.listRepeater ul li.MuiListSubheader-sticky {
    border-bottom: 1.1px solid #c1a6a6 !important;
    color: #0F4D8F;
    font-size: 14px;
    font-weight: bold;
}

.listRepeater ul li div.MuiBox-root.listDiv {
    width: 98% !important;
    padding: 12px !important;
    background: #eff3f2;
    height: 100%;
}

.listRepeater li ul div.listSubDiv{
    background: #fff !important;
    border-bottom: 1.1px solid #c1a6a6 !important;
}

.listRepeater ul li div.MuiBox-root subDiv{
    background: #fff !important;
    border-bottom: 1.1px solid #c1a6a6 !important;
    height: 84px;
}

#activityLogIntervalIcon div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    margin-left:20px;
}

svg > g > g.google-visualization-tooltip { pointer-events: none }

.datePicker div.MuiFormControl-root .MuiOutlinedInput-root {
  height:26px !important;
  width:100% !important;
}

.filpVertically {
  -webkit-transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;

  filter: FlipH !important;
  -ms-filter: "FlipH" !important;
}

.addUsers td {
  font-size: 13px;
}

.addUsers td div {
  font-size: 13px !important;
}

.addUserPasscode input{
  text-transform: uppercase;
}

.searchFilterAddUser input {
  background: #fff;
  text-indent: 4px;
  font-size: 13px;
  margin-top: 6px;
  border-radius: 4px;
  margin-right: 15px;
}


.createAlertSearch div input {
  height: 10px;  
}


@media screen and (min-width:600px){
  .pcToolhistoryDialog div.MuiPaper-root{
    width: 75% !important;
    max-width: 75% !important;
  }
  .downloadLogsDiv {
    display:flex;
    flex-direction:row;
  }
  .cancelButton {
    margin-left: auto !important;
  }  
}

@media screen and (max-width:599px){
  .pcToolhistoryDialog div.MuiPaper-root{
    width: 90% !important;
    max-width: 90% !important;
  }

  .downloadLogsDiv {
    display:flex;
    flex-direction:row;
  }
  
  .cancelButton {
    margin-left: auto !important;
  }

  .fixedBody {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width:400px){
  .pcToolhistoryDialog div.MuiPaper-root{
    width: 100% !important;
    max-width: 100% !important;
  }
}

.addUsersDiv {
  display: flex;
  flex-direction: row;
}

.addUsersButton {
  margin-left: auto !important;
}

@media only screen and (max-width: 200px) {  
  .downloadLogsDiv {
    display:flex;
    flex-direction:column;
  }  
  .addUsersDiv {
    display: flex;
    flex-direction: column !important;
  }
  .addUsersButton {
    margin-left: 0px !important;
  }
}


@-webkit-keyframes spin{
  from{
  -moz-transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  }
  100%{
  -moz-transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  }
}
@-moz-keyframes spin{
  from{
  -moz-transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  }
  100%{
  -moz-transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  }
}

@-o-keyframes spin{
  from{
  -moz-transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  }
  100%{
  -moz-transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  }
}

@-ms-keyframes spin{
  from{
  -moz-transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  }
  100%{
  -moz-transform: rotateZ(360deg);
  -webkit-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  }
}

@keyframes spin {
  from{
    -moz-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    }
    100%{
    -moz-transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    }
}

.instruction_pending_icon {
  -webkit-animation: spin 2s infinite; /* Chrome, Safari, Opera */
  animation: spin 2s infinite;
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
}

.usersTextBox .MuiFormHelperText-root {
  text-align: end;
  color: rgba(0,0,0,.87);
}

.nameTextField .MuiFormHelperText-root {
  color: red;
}

.scanTextField input {
  text-align: left;
}

.userDialogRemoveScheduleCheckBox svg {
  font-size: 14px;
}

@media only screen and (min-width:301px){
  .scanOrRssiApplyDiv  {
    display: flex;
    flex-direction: row;    
  }  
  .scanOrRssiApplyDiv .minsTextField {
    width: 100% !important;
  }
}

@media only screen and (max-width:300px){
  .scanOrRssiApplyDiv {
    display: flex;
    flex-direction: column;
  }  
}

@media only screen and (max-width: 599px) {
  .filterNameForSmallScreens {
    max-width:100px;
    min-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.modeSwitch div span {
  width: 103px;
  height: 24px;  
}

.modeSwitch div span span {
  left: -34px;
  top: 4px;
}

.modeSwitchButton .Mui-checked {
  color: rgb(20, 19, 139) !important;
}

.redText {
  color: red;
}

.greenText {
  color: green;
}