@media screen and (max-width: 959px) {
    .leftPane {
        width: 100%;
    }
    .rightPane {
        display:none;
    }
}

@media screen and (min-width: 960px) {
    .leftPane {
        width: 50%;
    }
    .rightPane {
        width: 50%;
    }
}

.gatewayMqttStatusIcon {
    height:5px;
    width:5px;    
    border-radius:50%;
    margin-left: -10px;
    margin-bottom: 10px;
    outline:2px solid white;
    display: inline-flex;
    position: relative;
    top: -9px;
    cursor: pointer;
}

.greenDot {
    height:5px;
    width:5px;
    background:#008000;
    border-radius:50%;
    margin-left: -10px;
    margin-bottom: 10px;
    outline:2px solid white;
    display: inline-flex;
    position: relative;
    top: -40px;
    left: 13px;
    cursor: pointer;
}

.redDot {
    height:5px;
    width:5px;
    background:#FF0000;
    border-radius:50%;
    margin-left: -10px;
    margin-bottom: 10px;
    outline:2px solid white;
    display: inline-flex;
    position: relative;
    top: -40px;
    left: 13px;
    cursor: pointer;
}

.redDotOffline {
    height:5px;
    width:5px;
    background:#FF0000;
    border-radius:50%;
    margin-left: 3px;
    margin-bottom: 10px;
    outline:2px solid white;
    display: inline-flex;
    position: relative;
    top: -40px;
    left: 13px;
    cursor: pointer;
}

.connectedGatewayMqttStatusIcon {
    background:#008000;
    left : 2px;
}

.connectedGatewayMqttStatusIconInactive{
    background:#008000;
    left: -6px;
}

.disconnectedGatewayMqttStatusIcon{
    background:#FF0000;
    left: 2px;
}

.disconnectedGatewayMqttStatusIconInactive{
    background:#FF0000;
    left: -6px;
}

.autocompleteDiv {
    width: 98%;
    height: 35px;
}

.mobileRightPane {
    position: absolute;
    left: 0px;
    width: 100%;
    z-index: 5000;
    background: #fff;
}


@media only screen and (min-width: 600px){
    .gatewayStatusDialogActivityLogs{
        height: 70vh
    }
  }