.homePageContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
  flex-direction: column;
  margin-bottom: 10%;
}

.UsersDialog div p{
  text-align:left !important;
}

.enterpriseConainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
}

.subContainerBlock {
  flex-direction: row;
  display: flex;
  max-height: 100%;
}
.adminBlock {
  flex: 50%;
  margin-top: 16px;
}
.adminSubBlock {
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  background: #fff;
  color:  #000000DE;
}

.enterpriseSubBlock {
  -webkit-box-shadow:  0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow:  0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow:  0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  width: 100%;
  background: #fff;
  color:  #000000DE;
}

.nonEnterpiseBlock {
  flex: 50%;
  margin-top: 16px;
  border: 1px solid black;
}

hr {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0px;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.subContainerBlockTitle {
  margin-left: 12px;
  font-size: 20px;
  font-weight: 200;
  color: #414c59;
  padding-top: 10px;
  margin-bottom: 10px;
}

.cardBlock {
  margin: 8px;
  margin-left: 20px;
  padding-top: 4px;
  padding-bottom: 18px;
  height: calc(110px);
  width: 50%;
}

.enterpiseCardBlock {
  margin: 8px;
  margin-left: 20px;
  padding-top: 4px;
  padding-bottom: 18px;
  height: calc(110px);
  width: 20%;
}

.cardIcons {
  display: flex;
  padding-top: 10px;
}

.cardIconBlock {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.1));
  width: 30px;
  height: 100%;
}

.hide {
  visibility: hidden;
}

.hide:hover {
  visibility: visible;
}

.cardIcoMenunBlock {
  width: 42px;
  height: 100%;
}

.innerCardBlock {
  height: 98px;
  margin-left: 2px;
  display: flex;
  width: 100%;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  border-top: 0px;
}

.cardList {
  width: 95%;
  height: 100%;
  max-width: 100%;
  padding: 0px;
  cursor: pointer;
}

.customesBlock {
  width: 19%;
  padding: 8px 0px 10px 10px;
}

.cardText {
  display: flex;
  flex: 100%;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
}

.menuItems {
  font-size: 15px;
  margin: auto 0;
  font-weight: 400;
  text-align: start;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-block;
  align-items: baseline;
  width: 100%;
}

.editText {
  display: inline-block;
}

.deleteText {
  display: inline-block;
}

.nameTextField {
  display: flex;
  flex-direction: row;
}

.firstName {
  width: 50%;
  margin-right: 10px;
}

.lastName {
  width: 50%;
}

.paddingBottom {
  margin-top: 10px;
}

.homePagesubContainer {
  width: 93.5%;
  margin: auto;
}

.blockWidth {
  width: '99%';
}

label.MuiInputLabel-root {
  font-size: 17px;
}

input.MuiSelect-nativeInput {
  text-align: left;
  float: left;
}

@media only screen and (max-width: 599px) {
  /* For very small devices */
  .subContainerBlock {
    flex-direction: column;
    display: flex;
    max-height: 100%;
  }

  .innerCardBlock {
    width: 85%;
  }

  .blockWidth {
    width: '100%';
  }

  .firstName {
    width: 100%;
  }

  .lastName {
    width: 100%;
  }

  .cardList {
    width: 95%;
    height: 100%;
    max-width: 100%;
    padding: 0px;
  }

  .cardBlock {
    margin: 8px;
    margin-left: 20px;
    padding-top: 4px;
    padding-bottom: 18px;
    height: calc(110px);
    width: 98%;
  }
  .customesBlock {
    width: 99.5%;
    padding: 8px 0px 10px 10px;
  }

  .enterprisecardList {
    width: 95%;
    height: 100%;
    max-width: 100%;
    padding: 0px;
    cursor: pointer;
  }

  .tenantName {
    flex: 80%;
    height: 100%;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dialogStyle {
    width: 100%;
    height: 100%;
  }

  .nameTextField {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 600px) and (max-width: 959px) {
  /* For small devices  */

  .subContainerBlock {
    flex-direction: column;
    display: flex;
    max-height: 100%;
  }

  .enterprisecardList {
    width: 100%;
    height: 100%;
    padding: 0px;
    cursor: pointer;
  }

  .customesBlock {
    width: 48%;
    padding: 8px 0px 10px 10px;
  }

  .tenantName {
    flex: 80%;
    height: 100%;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cardBlock {
    margin: 8px;
    margin-left: 20px;
    padding-top: 4px;
    padding-bottom: 18px;
    height: calc(110px);
    width: 50%;
  }

  .dialogStyle {
    width: 50%;
    height: 50%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  /* For medium devices */
  .enterprisecardList {
    width: 100%;
    height: 100%;
    padding: 0px;
    cursor: pointer;
  }

  .customesBlock {
    width: 32%;
    padding: 8px 0px 10px 10px;
  }

  .tenantName {
    flex: 80%;
    padding-left: 10px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dialogStyle {
    width: 30%;
    height: 50%;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1919px) {
  /* For large devices */
  .enterprisecardList {
    width: 100%;
    height: 100%;
    padding: 0px;
    cursor: pointer;
  }

  .customesBlock {
    width: 19%;
    padding: 8px 0px 10px 10px;
  }

  .tenantName {
    flex: 80%;
    height: 100%;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dialogStyle {
    width: 30%;
    height: 50%;
  }
}

@media only screen and (min-width: 1920px) {
  /* For extra large devices */
  .enterprisecardList {
    width: 100%;
    height: 100%;
    padding: 0px;
    cursor: pointer;
  }
  .customesBlock {
    width: 19%;
    padding: 8px 0px 10px 10px;
  }
  .tenantName {
    flex: 80%;
    padding-left: 10px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1280px) {
  .editText {
    display: none;
  }
  .deleteText {
    display: none;
  }
  .dialogStyle {
    width: 30%;
    height: 50%;
  }
}

@keyframes thin_shadow_progress_anim {
    from {
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
    }
    25% {
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
    }
    50% {
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
    }
    75% {
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
    }
    100% {
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.6);
    }
}

.tenantDeletePending {
    -webkit-animation: thin_shadow_progress_anim 1s infinite;
    animation: thin_shadow_progress_anim 1s infinite;
}