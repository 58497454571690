/* locks.module.css */
@media only screen and (max-width: 768px) {
  .css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
  .rowPerPageLabelBlock {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .MuiTablePagination-selectLabel {
    margin-left: auto;
  }
  .MuiTablePagination-displayedRows {
    margin-left: auto;
    margin-right: 32px;
  }
  .navigationIcons {
    margin-left: auto;
    margin-right: 16px;
  }

  .pageBox {
    margin-bottom: 16px;
  }
  .pageSelectBox {
    margin-bottom: 16px;
  }
  .rowBox {
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 769px) {
  .css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex;
    flex-direction: row;
  }
  .rowPerPageLabelBlock {
    display: flex;
    flex-direction: row;
    margin: 17px 0px 15px auto;
  }

  .navigationIcons {
    margin-left: 32px;
    margin-top: 32px;
  }
}

.MuiTablePagination-spacer {
  width: 40%;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-input {
  display: none;
}

.navigationIcons {
  min-width: 147px;
  height: 56px;
  text-align: center;
}

.MuiSelect-root {
  height: 26px;
  min-width: 108px;
  text-align: right;
  margin-right: 32px;
}

.cursorPointer {
  margin-right: 12px;
}

table .MuiTableHead-root th {
  padding: 16px 0px 18px 16px;
  width: 150px;
  font-size: 14px;
  font-weight: 700;
}

td {
  width: 150px;
}

.showAnimation {
  -webkit-animation: thin_shadow_progress_tile_anim 1s infinite;
  animation: thin_shadow_progress_tile_anim 1s infinite;
}


.rowPerPageLabelDiv {
    display: flex !important;
    flex-direction: row !important;
}

@keyframes thin_shadow_progress_tile_anim {
  0% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.7);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.7);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.7);
  }
  20% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.5);
  }
  40% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.3);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.3);
  }
  50% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.1);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.1);
  }
  60% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.3);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.3);
  }
  80% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.5);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.7);
    -moz-box-shadow: 0 0 5px 2px rgba(255, 0, 0, 0.7);
    box-shadow: 0 0 10px 2px rgba(255, 0, 0, 0.7);
  }
}