nav {
  height: 64px;
  padding: 0px 16px;
  background-color: rgb(20, 19, 139);
  color: rgba(255, 255, 255, 0.87);
}

.div-header {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-img {
  height: 85%;
  padding: 4px;
  cursor: pointer;
}

.top-right-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account_popover .name {
  font-weight: 400;
  color: black;
  line-height: 1.5;
  font-size: 15px;
}

.account_popover .email {
  font-weight: 300;
  color: grey;
  line-height: 1.5;
  font-size: 15px;
}

.account_popover .row_1 {
  padding: 12px 16px;
}

.account_popover MenuItem {
  text-decoration: none;
  line-height: 1.5;
  font-size: 15px;
  display: block;
  font-weight: 400;
  color: #000;
}
