.rowPerPageLabelDiv{
    display:flex;
    flex-direction: row;
    font-size:13px;
}
.page {
    display: flex;
    align-items: center;
}

.pageSelectBox {
    height: 25px;
    margin-right:32px;
}

.rowPerPageLabelDiv {
    display: flex;
    align-items: center;
}

.rowPerPageSelectBox {
    margin-right:32px;
    font-size:13px;
}

.paginationActionIcons {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.tableBodyRow td {
    alignItems:center;
    justifyContent:center;
    line-height:0.99;
    font-size: 13px;
}

.tBodyTableCell {
    align-items:center !important;
    justify-content:center !important;
    line-height:0.99 !important;
    font-size: 13px !important;
}

.tableBodyRow {
    border-bottom: 1px solid #c1a6a6;
}

