.devices_grid_list .grid_card:hover {
  transition: box-shadow 0.25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.devices_grid_list .battery {
  position: relative;
  width: 20px;
  height: 44px;
  border: 2px solid #fff;
  border-radius: 2px;
  position: relative;
  padding: 1px;
  -webkit-transform: scale(0.5);
  top: -8px;
  left: -2px;
}

.devices_grid_list .battery:before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 7px;
  width: 8px;
  height: 8px;
  background: #fff;
  clip: rect(0px, 8px, 4px, 0px);
}

.devices_grid_list .battery .level {
  width: 100%;
  height: 20%;
  background: #fff;
  bottom: 0px;
  left: 0px;
  position: absolute;
}

.devices_grid_list .reachable {
  background-color: #14138b !important;
}

.devices_grid_list .not_reachable {
}

.devices_grid_list img {
  outline: none;
  border: 0;
}

.devices_grid_list .reachable img:active,
.devices_grid_list .reachable i:active {
  /*margin-top: 2px;*/
  transform: scale(0.97);
}

.devices_grid_list .auth_failed_device i:focus {
  outline-color: #e0e0e0;
}

.devices_grid_list .card_container {
  height: 100%;
  width: 100%;
}

.devices_grid_list .grid_card {
  width: 100%;
  color: white;
}

.devices_grid_list md-card-title {
  position: relative;
  padding: 2px;
}

.devices_grid_list .battery_container {
  /*height: 100%;*/
}

.devices_grid_list .battery_outer_container {
  padding-top: 12px;
  padding-left: 12px;
}

.devices_grid_list .battery_indicator_container {
  position: relative;
  top: -18px;
}

.devices_grid_list .battery_indicator {
  font-size: 12px;
}

.devices_grid_list .locked_unlocked_image {
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.devices_grid_list .overflow_container {
  padding-top: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.device_name {
  font-weight: 500;
  color: white;
}

.device_action {
  border: 1px solid white;
  border-radius: 3%;
}

.gridCard {
  text-align: center;
  height: 400px;
  border-radius: 10%;
  font-weight: bold;
}