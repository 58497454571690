@media only screen and (min-width: 1280px) {
  .container {
    display: flex;
    margin: auto;
    width: 75%;
    max-height: 100%;
    color: black;
  }
}
.subBlock {
  display: flex;
  flex-direction: row;
  background: #fff;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1279px) {
  .container {
    display: flex;
    margin: auto;
    width: 90%;
    max-height: 100%;
    color: black;
  }
}

@media only screen and (max-width: 959px) {
  .subBlock {
    display: flex;
    background: #fff;
    flex-direction: column;
  }
}

.subContainer {
  -webkit-box-shadow: 0 7px 0px -6px #777;
  -moz-box-shadow: 0 7px 0px -6px #777;
  box-shadow: 0 7px 0px -6px #777;
  background: #fff;
  margin: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.backArrow {
  margin: 0 6px;
  height: 40px;
  min-width: 0;
  line-height: 24px;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.portions {
  display: flex;
  height: 100%;
  flex: 50;
  flex-direction: column;
  background: #fff;
}

.selectedBlock {
  border: 1px solid #ccc;
  border-style: dashed;
  margin: 8px;
  padding: 20px;
  font-weight: 300;
  text-align: center;
}

.selectedBlockExist {
  border: 1px solid #ccc;
  border-style: dashed;
  margin: 8px;
  padding: 20px;
  font-weight: 300;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 8px;
  margin-left: 16px;
}

.radioButton {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

.tenantsBlock {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 400px;
}

.searchBlock {
  display: flex;
  flex-direction: row;
}

.allTenants {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 0px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.bottomDiv {
  flex: 20;
  padding: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 7px 0px -6px #777;
  -moz-box-shadow: 0 7px 0px -6px #777;
  box-shadow: 0 7px 0px -6px #777;
  background: #fff;
}

.tenantItems {
  display: flex;
  flex: 100;
  flex-direction: row;
}

.removeCircle {
  display: none;
}

@media only screen and (min-width:600px) {
  .playStoreIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width:599px) {
  .playStoreIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
  }
}