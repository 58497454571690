.wesko_lock_image {
  max-height: 100%;
  max-width: 100%;
  opacity: 0.8;
  margin: 36px 0 0 0;
}

.loginComponent .MuiGrid-root.MuiGrid-item.loginFooter{
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-left:-10px;
}

.emailTextField label.MuiInputLabel-root {
    color:rgb(231,87,83) !important;
}

.emailTextField .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:before{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.emailTextField .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:after{
    border-bottom: 2px solid rgb(231,87,83) !important;
}