.elivatedBox {
  background-color: rgba(0, 0, 0, 0.2);
  border: solid #fff;
  border-top-color: rgb(255, 255, 255);
  border-top-style: solid;
  border-top-width: initial;
  border-right-color: rgb(255, 255, 255);
  border-right-style: solid;
  border-right-width: initial;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: initial;
  border-left-color: rgb(255, 255, 255);
  border-left-style: solid;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}
