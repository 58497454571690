@media only screen and (min-width: 1280px) {
  .migrateContainer {
    display: flex;
    margin: auto;
    width: 75%;
    max-height: 100%;
    color: black;
  }
}
.migrateSubBlock {
  display: flex;
  flex-direction: row;
  background: #fff;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1279px) {
  .migrateContainer {
    display: flex;
    margin: auto;
    width: 90%;
    max-height: 100%;
    color: black;
  }
}

@media only screen and (max-width: 959px) {
  .migrateSubBlock {
    display: flex;
    background: #fff;
    flex-direction: column;
  }
}

@media only screen and (max-width: 399px) {
  .migrateSelectedBlock {
    display: flex;
    flex-direction: column;
  }
  .migrateCardText {
    width: 40%;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 400px) {
  .migrateSelectedBlock {
    display: flex;
    flex-direction: row;
  }
  .migrateCardText {
    width: 100%;
    text-overflow: 'ellipsis';
  }
}

.migrateSubContainer {
  -webkit-box-shadow: 0 7px 0px -6px #777;
  -moz-box-shadow: 0 7px 0px -6px #777;
  box-shadow: 0 7px 0px -6px #777;
  background: #fff;
  margin: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.migratePortions {
  display: flex;
  height: 100%;
  flex: 50;
  flex-direction: column;
  background: #fff;
}

.migrateSelectedBlock {
  border: 1px solid #ccc;
  margin: 16px;
  padding: 20px;
  text-align: left;
}


@media only screen and (min-width: 960px) {
    .step4migrateLocksDiv {
        display: flex;
        flex-direction: row;        
    }
    .step4migrateLocksSubDiv{
      display: flex;
      flex-direction: column;
      width:50%;
      padding: 10px;
      margin :16px 10px 16px 29px;
    }
    .step4migrateUsersSubDiv {
      margin: 16px 29px 16px 10px;
    }
}

@media only screen and (max-width: 959px) {
  .step4migrateLocksDiv {
      display: flex;
      flex-direction: column;        
  }
  .step4migrateLocksSubDiv{
    display: flex;
    flex-direction: column;
    width:95%;    
    margin: 20px auto 20px auto !important;
  }
  .step4migrateUsersSubDiv {
    margin: 20px auto 20px auto !important;
  }
}

.noMigrateUsers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
}

.noMigrateLocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
}